exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-add-to-slack-index-tsx": () => import("./../../../src/pages/add-to-slack/index.tsx" /* webpackChunkName: "component---src-pages-add-to-slack-index-tsx" */),
  "component---src-pages-add-to-teams-index-tsx": () => import("./../../../src/pages/add-to-teams/index.tsx" /* webpackChunkName: "component---src-pages-add-to-teams-index-tsx" */),
  "component---src-pages-blogs-index-tsx": () => import("./../../../src/pages/blogs/index.tsx" /* webpackChunkName: "component---src-pages-blogs-index-tsx" */),
  "component---src-pages-blogs-prismic-blog-page-uid-tsx": () => import("./../../../src/pages/blogs/{PrismicBlogPage.uid}.tsx" /* webpackChunkName: "component---src-pages-blogs-prismic-blog-page-uid-tsx" */),
  "component---src-pages-case-studies-index-tsx": () => import("./../../../src/pages/case-studies/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-index-tsx" */),
  "component---src-pages-case-studies-prismic-case-studies-uid-tsx": () => import("./../../../src/pages/case-studies/{PrismicCaseStudies.uid}.tsx" /* webpackChunkName: "component---src-pages-case-studies-prismic-case-studies-uid-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-index-tsx": () => import("./../../../src/pages/pricing/index.tsx" /* webpackChunkName: "component---src-pages-pricing-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-security-features-tsx": () => import("./../../../src/pages/security-features.tsx" /* webpackChunkName: "component---src-pages-security-features-tsx" */),
  "component---src-pages-terms-of-services-index-tsx": () => import("./../../../src/pages/terms-of-services/index.tsx" /* webpackChunkName: "component---src-pages-terms-of-services-index-tsx" */)
}


import { PrismicProvider } from "@prismicio/react"
import { Link } from "gatsby"
import * as React from "react"
import "./src/styles/_index.scss"
import AOS from "aos"
import "./node_modules/aos/dist/aos.css"

AOS.init()

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    internalLinkComponent={({ href, ...props }) => (
      <Link to={href} {...props} />
    )}
  >
    {element}
  </PrismicProvider>
)

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const { pathname } = location

  // List of routes that should always scroll to top
  const scrollToTopRoutes = [
    "/privacy-policy",
    "/blogs",
    "/security-features",
    "/terms-of-services",
    "/case-studies",
  ]

  // Check if current path starts with any of the routes
  const shouldScrollToTop = scrollToTopRoutes.some(route =>
    pathname.startsWith(route)
  )

  // If it's a scroll-to-top route or a new navigation (not back/forward)
  if (shouldScrollToTop || location.action === "PUSH") {
    window.scrollTo({ top: 0, behavior: "smooth" })
    return false
  }

  // For back/forward navigation, restore the saved position
  const savedPosition = getSavedScrollPosition(location)
  return savedPosition || [0, 0]
}

const initApollo = () => {
  console.log("Apollo tracker initializing...")
  var n = Math.random().toString(36).substring(7)
  var o = document.createElement("script")
  o.src =
    "https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache=" +
    n
  o.async = true
  o.defer = true
  o.onload = function () {
    if (window.trackingFunctions) {
      window.trackingFunctions.onLoad({ appId: "6657801b336104048b74a0bc" })
    }
  }
  document.head.appendChild(o)
}

// ✅ Function to load G2 Attribution Tracking
const initG2Tracking = () => {
  console.log("G2 Attribution Tracking initializing...")
  var c = "1023178" // G2 customer ID
  var p = document.location.href
  var d = document
  var id = "" // Optional Custom ID for user in your system
  var u =
    "https://tracking-api.g2.com/attribution_tracking/conversions/" +
    c +
    ".js?p=" +
    encodeURI(p) +
    "&e=" +
    id
  var i = document.createElement("script")
  i.type = "application/javascript"
  i.async = true
  i.src = u
  d.getElementsByTagName("head")[0].appendChild(i)
}

// ✅ Call functions properly inside onInitialClientRender
export const onInitialClientRender = () => {
  if (typeof window === "undefined") return

  initApollo()
  initG2Tracking()
}
